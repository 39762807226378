/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19/11/2022.
 */
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

export const Support = () => {
  const { t } = useTranslation()

  return (
    <div className="container-fluid" style={{ background: '#EFF2FD' }}>
      <div className="container">
        <div className="row " style={{}}>
          <div className="col-12 d-flex justify-content-md-end justify-content-center align-items-center col-md-6 mt-6 mt-md-2 mt-md-0 ">
            <div className="px-lg-6 px-2" style={{}}>
              <StaticImage
                placeholder={'none'}
                quality={100}
                loading="lazy"
                src={'../images/support.png'}
                alt="suppport"
              ></StaticImage>
            </div>
          </div>
          <div className="col-12 col-md-6 my-0 my-md-6 mb-4 d-flex">
            <div className="">
              {/* Whats In It For You */}
              <h5 className="fw-bold text-center text-md-start mt-5 mb-4">{t('supportHeadline')}</h5>

              <div className="mb-3 text-center text-md-start pb-3" style={{ maxWidth: 500 }}>
                <p>{t('footer.reachOutHere')}</p>
                <p className="p-large-bold mt-3">support@studiesandme.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
