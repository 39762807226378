/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 20/02/2021.
 */

import { GATSBY_NODE_ENV } from '../constant'
import { win } from './browserMock'

export const sendToDataLayer = (obj) => {
  if (GATSBY_NODE_ENV === 'development') {
    console.log(' DataLayer > GATSBY_NODE_ENV = ', GATSBY_NODE_ENV)
    console.log(' DataLayer > is not active ', obj)
    return
  }

  win.dataLayer = win.dataLayer || {}
  win.dataLayer.push(obj)
}
